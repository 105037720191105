
export const SITE_SETTING_FAIL = "SITE_SETTING_FAIL";
export const SITE_SETTING_SUCCESS = "SITE_SETTING_SUCCESS";
export const SITE_SETTING_REQUEST = "SITE_SETTING_REQUEST";


export const SERVICE_SUCCESS = "SERVICE_SUCCESS";
export const DELIVERY_SERVICE_LIST = "DELIVERY_SERVICE_LIST";

export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_FAIL = "CONTACT_FAIL";
