import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfo, faTimes } from '@fortawesome/free-solid-svg-icons';

const AlertTemplate = ({ style, options, message, close }) => {
  console.log("options", options);
  return (
    <div className="custom-alert-box-area" style={style}>
      <button className="custom-alert-backdrop" onClick={close}></button>
      <div className="custom-alert-box">
        {options.type === 'info' &&
          <div className="icon info">
            <FontAwesomeIcon icon={faInfo} />
          </div>
        }
        {options.type === 'success' &&
          <div className="icon success">
            <FontAwesomeIcon icon={faCheck} />
          </div>
        }
        {options.type === 'error' &&
          <div className="icon error">
            <FontAwesomeIcon icon={faTimes} />
          </div>
        }
        <div className="message">{message}</div>
        {options.confirm &&
          <div className="alert-buttons">
            <button type="button" onClick={options.removeToCollection} className="alert-ok-button">Confirm</button>
            <button type="button" onClick={close} className="alert-cancel-button">Cancel</button>
          </div>
        }
      </div>
    </div>
  )
}

export default AlertTemplate;